import { useEffect, useState } from 'react';
import s from './styles.module.css';
import {
    ArrowIcon,
    ClockIcon,
    InstagramIcon,
    LocationIcon,
    PackIcon,
    PhoneSmallIcon,
    TelegramIcon,
    WhatsAppIcon,
} from '../../assets';
import { useNavigate } from 'react-router-dom';
import { Typography } from 'antd';
import { getBranchList } from '../../api/admin/functions';
import axios from 'axios';
import { baseURL } from '../../api/baseURL';
import { useQuery } from 'react-query';
import OrderReport from './orderReport/orderReport';
import Cookies from 'js-cookie';
import useOrderReport from '../../api/admin-main/admin-main-hooks';
import Spinner from '../UI/Spinner/Spinner';

const getOrderReportTurkey = async () => {
    const response = await axios.get(`${baseURL}amantrans/order_turkey/orders/report`, {
        headers: {
            Authorization: `Bearer ${Cookies.get('token')}`,
        },
    });

    return response.data;
};
const ClientMainPage = ({ t, language }) => {
    const navigate = useNavigate();
    const [showAllFilials, setShowAllFilials] = useState(false);
    const [filialsList, setFilialisList] = useState([]);
    const { data, refetch: ChinaRefetch } = useOrderReport();
    const handleToggleFilials = () => {
        setShowAllFilials((prev) => !prev);
    };
    const {
        data: TurkeyOrder,
        refetch: TurkeyRefetch,
        isLoading,
        isError,
    } = useQuery(['turkey-main'], getOrderReportTurkey);

    useEffect(() => {
        getBranchList({}).then((data) => setFilialisList(data.content));
    }, []);
    const formatPhoneNumber = (phoneNumber) => {
        const cleaned = ('' + phoneNumber).replace(/\D/g, '');
        const match = cleaned.match(/^(\d{1})(\d{3})(\d{3})(\d{2})(\d{2})$/);
        if (match) {
            return `+${match[1]} (${match[2]}) ${match[3]}-${match[4]}-${match[5]}`;
        }
        return phoneNumber;
    };
    if (isLoading) {
        return (
            <div>
                <Spinner />
            </div>
        );
    }

    if (isError) {
        return <div>Error fetching data...</div>;
    }
    return (
        <div className={s['container']}>
            <div className={s['container-report']}>
                <OrderReport
                    ChinaRefetch={ChinaRefetch}
                    translation={t}
                    data={data}
                    language={language}
                    turkeyOrder={TurkeyOrder}
                    TurkeyRefetch={TurkeyRefetch}
                />
            </div>
            <div className={s['card']}>
                <h1 className={s['title']}>{t('ourContacts')}</h1>
                <div className={s['row-wrap']}>
                    <a
                        className={s['row-item-grey']}
                        href="https://www.instagram.com/amantrans.kz/"
                        target="_blank"
                        rel="noreferrer"
                    >
                        <img src={InstagramIcon} alt="" />
                        <span>AmanTrans</span>
                    </a>
                    <a
                        className={s['row-item-grey']}
                        href="https://wa.me/77070491088"
                        target="_blank"
                        rel="noreferrer"
                    >
                        <img src={WhatsAppIcon} alt="" />
                        <span> {formatPhoneNumber(77070491088)}</span>
                    </a>
                    <a
                        className={s['row-item-grey']}
                        href="https://t.me/+YIfXk-5chaU3MWUy"
                        target="_blank"
                        rel="noreferrer"
                    >
                        <img src={TelegramIcon} alt="" />
                        <span>AmanTrans</span>
                    </a>
                </div>
            </div>
            <div>
                <h1 className={s['title']}>{t('ourFilials')}</h1>
                <div className={s['grid-wrap']}>
                    {filialsList?.slice(0, 3)?.map((filial) => (
                        <div className={s['card']} style={{ gap: '6px' }}>
                            <Typography.Title style={{ fontSize: '20px' }}>
                                {filial?.cityName}
                            </Typography.Title>
                            <div className={s['row-item']}>
                                <img src={PhoneSmallIcon} alt={PhoneSmallIcon} />
                                <a
                                    href={`tel:${filial?.moderator_phone}`}
                                    className={s['grey-text']}
                                >
                                    {formatPhoneNumber(filial?.moderator_phone)}
                                </a>
                            </div>
                            <div className={s['row-item']}>
                                <img src={ClockIcon} alt="" />
                                <span className={s['grey-text']}>
                                    {filial?.workingHour} Обед: {filial?.lunch_break}, Выходные:{' '}
                                    {filial?.day_offs}
                                </span>
                            </div>
                            <div className={s['row-item']}>
                                <img src={PackIcon} alt="" />
                                <span className={s['grey-text']}>
                                    Из Турции до филиала -Express {filial.turkeyTariff}$/1кг.
                                    <br></br>Из Китая до филиала -Express {filial.chinaTariff}
                                    $/1кг
                                </span>
                            </div>
                            <div className={s['row-item']}>
                                <img src={LocationIcon} alt="" />
                                <span className={s['grey-text']}>{filial?.address}</span>
                            </div>
                        </div>
                    ))}
                    {showAllFilials &&
                        filialsList?.slice(3)?.map((filial) => (
                            <div className={s['card']} style={{ gap: '6px' }}>
                                <Typography.Title style={{ fontSize: '20px' }}>
                                    {filial?.cityName}
                                </Typography.Title>
                                <div className={s['row-item']}>
                                    <img src={PhoneSmallIcon} alt={PhoneSmallIcon} />
                                    <a
                                        href={`tel:${filial?.moderator_phone}`}
                                        className={s['grey-text']}
                                    >
                                        {formatPhoneNumber(filial?.moderator_phone)}
                                    </a>
                                </div>
                                <div className={s['row-item']}>
                                    <img src={ClockIcon} alt="" />
                                    <span className={s['grey-text']}>
                                        {filial?.workingHour} Обед: {filial?.lunch_break}, Выходные:{' '}
                                        {filial?.day_offs}
                                    </span>
                                </div>
                                <div className={s['row-item']}>
                                    <img src={PackIcon} alt="" />
                                    <span className={s['grey-text']}>
                                        Из Турции до филиала -Express {filial.turkeyTariff}$/1кг.{' '}
                                        <br></br>Из Китая до филиала -Express {filial.chinaTariff}
                                        $/1кг
                                    </span>
                                </div>
                                <div className={s['row-item']}>
                                    <img src={LocationIcon} alt="" />
                                    <span className={s['grey-text']}>{filial?.address}</span>
                                </div>
                            </div>
                        ))}
                </div>
                <div className={s['center-container']}>
                    <div className={s['blue-container']} onClick={handleToggleFilials}>
                        <span>{t(showAllFilials ? 'hideAllFilials' : 'showAllFilials')}</span>
                        <img src={ArrowIcon} alt="arrow" />
                    </div>
                </div>
            </div>
            <div>
                <h1 className={s['title']}>{t('additionalInfo')}</h1>
                <div className={s['row-wrap']}>
                    <div className={s['card2']}>
                        <Typography.Title style={{ fontSize: '20px', fontWeight: 800 }}>
                            {t('forbiddenStuff')}
                        </Typography.Title>
                        <div
                            className={s['blue-container']}
                            onClick={() => navigate('/client-main/forbidden')}
                        >
                            <span>{t('show')}</span>
                            <img src={ArrowIcon} alt="arrow" />
                        </div>
                    </div>
                    <div className={s['card2']}>
                        <Typography.Title style={{ fontSize: '20px', fontWeight: 800 }}>
                            {t('aggreement')}
                        </Typography.Title>
                        <div
                            className={s['blue-container']}
                            onClick={() => navigate('/client-main/aggreement')}
                        >
                            <span>{t('show')}</span>
                            <img src={ArrowIcon} alt="arrow" />
                        </div>
                    </div>
                    <div className={s['card2']}>
                        <Typography.Title style={{ fontSize: '20px', fontWeight: 800 }}>
                            {t('statuses')}
                        </Typography.Title>
                        <div
                            className={s['blue-container']}
                            onClick={() => navigate('/client-main/statuses')}
                        >
                            <span>{t('show')}</span>
                            <img src={ArrowIcon} alt="arrow" />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};
export default ClientMainPage;
