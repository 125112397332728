import s from './styles.module.css';
import EnterIcons from '../../icons/order-turkey-info-icons/EnterIcons';
import { useLocation, useNavigate } from 'react-router-dom';
import { getHistoryStatus } from '../../api/order-turkey-info-api/order-turkey-info-api';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import React, { useEffect, useState } from 'react';
import Spinner from '../UI/Spinner/Spinner';
import CustomModal from '../UI/Modal/Modal';
import { axiosInstances } from '../../api/axios-instances';
import StatusColor from '../UI/statusColor/statusColor';
import { format } from 'date-fns';
import { kk, ru } from 'date-fns/locale';
import Cookies from 'js-cookie';
import { jwtDecode } from 'jwt-decode';

const OrderTurkeyInfo = ({ t, language }) => {
    const [openModal, setOpenModal] = useState(false);
    const navigation = useNavigate();
    const [role, setRole] = useState(null);
    const location = useLocation();
    const [rowData, setRowData] = useState(location.state.rowData || {});
    const {
        data: statusData,
        error: ErrorStatus,
        isLoading: LoadingStatus,
    } = useQuery(['historyStatus', rowData.id], () =>
        getHistoryStatus({ id: rowData.id, country: 'Turkey' }),
    );
    const [errors, setErrors] = useState({});
    const [showErrorMessage, setShowErrorMessage] = useState(false);
    console.log(rowData);
    const validateInputs = () => {
        const newErrors = {};
        if (!inputData.name) newErrors.name = 'Имя пустое';
        if (!inputData.weight || isNaN(inputData.weight))
            newErrors.weight = 'Вес должен быть числом';
        setErrors(newErrors);
        return Object.keys(newErrors).length === 0;
    };

    const token = Cookies.get('token');
    const queryClient = useQueryClient();
    useEffect(() => {
        const token = Cookies.get('token');
        if (token) {
            try {
                const decodedToken = jwtDecode(token);
                setRole(decodedToken.role);
            } catch (error) {
                setRole('');
            }
        } else {
            setRole('');
        }
    }, [location.pathname]);
    const updateOrder = useMutation({
        mutationFn: async (user) => {
            const response = await axiosInstances.put(
                `/amantrans/order_turkey/admin/update/${rowData.id}`,
                user,
                {
                    headers: {
                        Authorization: `Bearer ${token}`,
                        'Content-Type': 'application/json',
                    },
                },
            );
            if (response.status !== 200) {
                throw new Error(`Error: ${response.status}`);
            }
            return response.data;
        },
        onSuccess: (data) => {
            queryClient.setQueryData(['orders', rowData.id], (oldData) => ({
                ...oldData,
                ...data,
            }));
            setRowData((prevData) => ({
                ...prevData,
                ...data,
            }));
            queryClient.invalidateQueries(['orders']);
        },
        onError: (error) => {
            console.error('Error updating customer:', error);
        },
    });
    const updateOrderByUser = useMutation({
        mutationFn: async (user) => {
            const response = await axiosInstances.put(
                `/amantrans/order_turkey/update/${rowData.id}`,
                user,
                {
                    headers: {
                        Authorization: `Bearer ${token}`,
                        'Content-Type': 'application/json',
                    },
                },
            );
            if (response.status !== 200) {
                throw new Error(`Error: ${response.status}`);
            }
            return response.data;
        },
        onSuccess: (data) => {
            queryClient.setQueryData(['orders', rowData.id], (oldData) => ({
                ...oldData,
                ...data,
            }));
            setRowData((prevData) => ({
                ...prevData,
                ...data,
            }));
            queryClient.invalidateQueries(['orders']);
        },
        onError: (error) => {
            console.error('Error updating customer:', error);
        },
    });

    const getBarcodeTurkey = useMutation({
        mutationFn: async (user) => {
            const response = await axiosInstances.post(
                `/amantrans/admin/turkey-order-code/generate`,
                user,
                {
                    responseType: 'blob',
                },
            );
            if (response.status !== 200) {
                throw new Error(`Error: ${response.status}`);
            }
            return response.data;
        },
        onSuccess: (data) => {
            const url = window.URL.createObjectURL(new Blob([data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', 'barcode.pdf');
            document.body.appendChild(link);
            link.click();
            link.parentNode.removeChild(link);
            window.URL.revokeObjectURL(url);
            queryClient.invalidateQueries(['orders']);
        },
        onError: (error) => {
            console.error('donwload error:', error);
        },
    });

    const [inputData, setInputData] = useState({
        name: '',
        weight: 0,
        track_code: '',
        tariff_price: 0,
        box_tariff: 0,
        additional_weight: 0,
        shipping_method: 'Avto',
    });
    const handleOpenModal = () => {
        setInputData({
            name: rowData.name || '',
            weight: rowData.weight || 0,
            track_code: rowData.track_code || '',
            tariff_price: rowData.tariff_price || 0,
            box_tariff: rowData.box_tariff || 0,
            additional_weight: rowData.additional_weight || 0,
            shipping_method: rowData.shipping_method || '',
        });
        setOpenModal(true);
    };

    const handleCloseModal = () => {
        setOpenModal(false);
        setInputData({
            name: '',
            weight: 0,
            track_code: '',
            tariff_price: 0,
            box_tariff: 0,
            additional_weight: 0,
            shipping_method: '',
        });
    };
    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setInputData((prevState) => ({
            ...prevState,
            [name]: value,
        }));
    };
    const getBarcodeTurkeyHandler = () => {
        const body = {
            country: 'Turkey',
            orderId: rowData.id,
        };
        getBarcodeTurkey.mutate(body);
    };
    const saveUserChanges = () => {
        const numberPrice = parseInt(inputData.price);
        const updateData = {
            name: inputData.name,
            price: numberPrice,
            amount: inputData.amount,
            weight: inputData.weight,
            track_code: inputData.track_code,
        };

        updateOrderByUser.mutate(updateData, {
            onSuccess: () => {
                setOpenModal(false);
                setInputData({
                    name: '',
                    price: 0,
                    amount: '',
                    weight: 0,
                    track_code: '',
                });
            },
            onError: (error) => {
                console.error('Error updating customer:', error);
            },
        });
    };

    const handleSave = () => {
        if (validateInputs()) {
            const updateData = {
                name: inputData.name,
                weight: inputData.weight,
                track_code: inputData.track_code,
                tariff_price: inputData.tariff_price,
                box_tariff: inputData.box_tariff || rowData.box_tariff,
                additional_weight: inputData.additional_weight,
                shipping_method: inputData.shipping_method,
            };

            updateOrderByUser.mutate(updateData, {
                onSuccess: () => {
                    setOpenModal(false);
                    queryClient.invalidateQueries(['orders']);
                },
                onError: (error) => {
                    console.error('Error updating order:', error);
                },
            });
        } else {
            setShowErrorMessage(true);
            setTimeout(() => {
                setErrors({});
                setShowErrorMessage(false);
            }, 2000);
        }
    };
    if (!rowData) {
        return <div>Нет данных для отображения</div>;
    }
    const date = new Date(rowData.created_at);
    if (LoadingStatus) return <Spinner />;
    if (ErrorStatus) return <div>There was an error loading orders!</div>;
    return (
        <div className={'container'}>
            <CustomModal visible={openModal} onClose={handleCloseModal}>
                <div className={s['modal-div']}>
                    <p className={s['modal-title']}>{t('editParameters')}</p>
                    <div className={s['input-div']}>
                        <div className={s['input-container']}>
                            <input
                                className={`${s['input-modal']} ${errors.name ? s['input-error'] : ''}`}
                                name="name"
                                value={inputData.name}
                                onChange={handleInputChange}
                            />
                            <label
                                className={`${s['floating-label']} ${inputData.name ? s.filled : ''}`}
                            >
                                {t('searchInputBracodePla')}
                            </label>
                        </div>
                        {role !== 'RoleClient' ? (
                            <div className={s['input-container']}>
                                <input
                                    className={`${s['input-modal']} ${
                                        errors.box_tariff ? s['input-error'] : ''
                                    }`}
                                    name="box_tariff"
                                    value={inputData.box_tariff}
                                    onChange={handleInputChange}
                                />
                                <label className={`${s['floating-label']}`}>Тариф коробки</label>
                            </div>
                        ) : (
                            ''
                        )}
                        {/*<div className={s['input-container']}>*/}
                        {/*    <input*/}
                        {/*        className={`${s['input-modal']} ${errors.price ? s['input-error'] : ''}`}*/}
                        {/*        name="price"*/}
                        {/*        type={'number'}*/}
                        {/*        value={inputData.price}*/}
                        {/*        onChange={handleInputChange}*/}
                        {/*    />*/}
                        {/*    <label*/}
                        {/*        className={`${s['floating-label']} ${inputData.price ? s.filled : ''}`}*/}
                        {/*    >*/}
                        {/*        {t('priceCargoInput')}*/}
                        {/*    </label>*/}
                        {/*</div>*/}
                        {/*<div className={s['input-container']}>*/}
                        {/*    <input*/}
                        {/*        type={'number'}*/}
                        {/*        className={`${s['input-modal']} ${errors.amount ? s['input-error'] : ''}`}*/}
                        {/*        name="amount"*/}
                        {/*        value={inputData.amount}*/}
                        {/*        onChange={handleInputChange}*/}
                        {/*    />*/}
                        {/*    <label*/}
                        {/*        className={`${s['floating-label']} ${inputData.amount ? s.filled : ''}`}*/}
                        {/*    >*/}
                        {/*        {t('quantity')}*/}
                        {/*    </label>*/}
                        {/*</div>*/}

                        <div className={s['input-container']}>
                            <input
                                className={`${s['input-modal']}`}
                                name="tariff_price"
                                value={inputData.tariff_price}
                                onChange={handleInputChange}
                            />
                            <label className={`${s['floating-label']}`}>{t('Тариф')}</label>
                        </div>
                        {role === 'RoleLeadAdmin' ? (
                            <>
                                <div className={s['input-container']}>
                                    <input
                                        type={'number'}
                                        className={`${s['input-modal']} ${errors.weight ? s['input-error'] : ''}`}
                                        name="weight"
                                        value={inputData.weight}
                                        onChange={handleInputChange}
                                    />
                                    <label
                                        className={`${s['floating-label']} ${inputData.weight ? s.filled : ''}`}
                                    >
                                        {t('weight')}
                                    </label>
                                </div>
                                <div className={s['input-container']}>
                                    <input
                                        className={`${s['input-modal']}`}
                                        type={'number'}
                                        name="additional_weight"
                                        value={inputData.additional_weight}
                                        onChange={handleInputChange}
                                    />
                                    <label className={`${s['floating-label']}`}>
                                        {t('additional_weight')}
                                    </label>
                                </div>
                                <div className={s['input-container']}>
                                    <select
                                        className={`${s['input-modal']}`}
                                        name="shipping_method"
                                        value={inputData.shipping_method}
                                        onChange={handleInputChange}
                                    >
                                        <option value="Avto">Avto</option>
                                        <option value="Avia">Avia</option>
                                    </select>
                                    <label className={`${s['floating-label']}`}>
                                        {t('type-delivery')}
                                    </label>{' '}
                                </div>
                            </>
                        ) : (
                            ''
                        )}
                    </div>
                    {showErrorMessage && (
                        <p className={s['error-message']}>Заполните, пожалуйста, все поля.</p>
                    )}
                    <div className={s['modal-button']}>
                        <button className={s['button-modal']} onClick={handleCloseModal}>
                            {t('cancel')}
                        </button>
                        <button className={s['button-modal']} onClick={handleSave}>
                            {t('edit')}
                        </button>
                    </div>
                </div>
            </CustomModal>
            <header className={s['header']}>
                <div className={s['header-div']}>
                    <button onClick={() => navigation(-1)} className={s['enter-style']}>
                        <EnterIcons />
                    </button>
                    <div className={s['header-text']}>
                        <p className={s['header-text-id']}>
                            {t('idCode')} / {rowData.customer.id_code}
                        </p>
                        <p className={s['header-text-order']}>
                            {t('order')} {rowData.name}
                        </p>
                        <p
                            style={StatusColor(rowData.status.name_ru)}
                            className={s['header-text-new']}
                        >
                            {' '}
                            {language === 'ru' ? rowData.status.name_ru : rowData.status.name_kz}
                        </p>
                    </div>
                </div>
            </header>
            <div className={s['content']}>
                <div className={s['order-info']}>
                    <div className={s['title-div']}>
                        <p className={s['title-text']}>{t('orderInformation')}</p>
                        {role === 'RoleClient' ? (
                            <p className={s['title-button']} onClick={getBarcodeTurkeyHandler}>
                                {t('printShipment')}
                            </p>
                        ) : (
                            <p className={s['title-button']} onClick={getBarcodeTurkeyHandler}>
                                {t('printShipment')}
                            </p>
                        )}
                    </div>
                    <div className={s['all-info']}>
                        {[
                            { label: t('orderName'), value: rowData.name || '' },
                            { label: t('idCode'), value: rowData.customer.id_code },
                            { label: t('trackCode'), value: rowData.track_code || '' },
                            { label: t('orderCreationDate'), value: date.toLocaleDateString() },
                            { label: t('buyer'), value: rowData.buyer_name || '' },
                            { label: t('amount'), value: rowData.amount || '' },
                            { label: t('orderSource'), value: rowData.order_source || '' },
                            {
                                label: t('tariffPrice'),
                                value: rowData.tariff_price ? `${rowData.tariff_price}$` : '',
                            },
                            { label: t('weight'), value: rowData.weight || '' },
                            { label: t('type-delivery'), value: rowData.shipping_method || '' },
                            {
                                label: t('additional_weight'),
                                value: rowData.additional_weight || '',
                            },
                            { label: t('status'), value: rowData.status.name_ru || '' },
                            { label: t('price'), value: rowData.price || '' },
                            { label: 'Тариф коробки', value: rowData.box_tariff || '' },
                            {
                                label: t('boxPrice'),
                                value: rowData.box_price ? `${rowData.box_price}$` : '',
                            },
                            {
                                label: t('deliveryPrice'),
                                value: rowData.delivery_price ? `${rowData.delivery_price}$` : '',
                            },
                        ].map((item, index) => (
                            <div className={s['order-details']} key={index}>
                                <span className={s['label']}>{item.label}</span>
                                <span className={s['dots']}></span>
                                <span className={s['value']}>{item.value}</span>
                            </div>
                        ))}
                    </div>
                    <div className={s['tarif-div']}>
                        <div className={s['price-div']}>
                            <p className={s['tariff-style']}>{t('total')}</p>
                            <p className={s['tariff-price']}>
                                {(
                                    (rowData.weight * rowData.tariff_price +
                                        rowData.box_tariff +
                                        rowData.additional_weight) *
                                    rowData.usd
                                ).toFixed(1)}
                                ₸
                            </p>
                        </div>
                        <div className={s['line-div']}></div>
                        <div className={s['price-div-tarif']}>
                            <p className={s['tariff-style']}>{t('tariff')}</p>
                            <p className={s['tariff-style-text']}>{rowData.tariff_price}$</p>
                        </div>
                    </div>
                    <div className={s['change-div']}>
                        <button onClick={() => handleOpenModal()} className={s['change-button']}>
                            {t('edit')}
                        </button>
                    </div>
                </div>
                <div className={s['history-div']}>
                    <div className={s['title-history-div']}>
                        <p className={s['title-history-style']}>{t('orderHistory')}</p>
                    </div>
                    {statusData.length === 0
                        ? null
                        : statusData.map((row) => {
                              const date = new Date(row.update_date);
                              const formattedDate = format(date, 'd MMMM yyyy', {
                                  locale: language === 'ru' ? ru : kk,
                              });
                              const formattedTime = format(date, 'HH:mm:ss', {
                                  locale: language === 'ru' ? ru : kk,
                              });

                              return (
                                  <div key={row.id}>
                                      <div className={s['history-text-div']}>
                                          <div className={s['date-div']}>
                                              <p className={s['date-day']}>{formattedDate}</p>
                                              <p className={s['date-time']}>{formattedTime}</p>
                                          </div>
                                          <div className={s['history-status-div']}>
                                              <p
                                                  style={StatusColor(row.status_to.name_ru)}
                                                  className={s['history-status']}
                                              >
                                                  {language === 'ru'
                                                      ? row.status_to.name_ru
                                                      : row.status_to.name_kz}
                                              </p>
                                          </div>
                                      </div>
                                  </div>
                              );
                          })}
                </div>
            </div>
        </div>
    );
};
export default OrderTurkeyInfo;
