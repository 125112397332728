import s from './styles.module.css';
import React, { useEffect, useState } from 'react';
import StatusColor from '../statusColor/statusColor';
import { format, parseISO } from 'date-fns';
import DatePicker from 'react-datepicker';
import { useQuery } from 'react-query';
import { getOrders } from '../../cargo-moderator/cargo-moderator';

const FilterChina = ({
    ordersData,
    language,
    citiesData,
    role,
    translation,
    visible,
    setShowModal,
    setActiveFilter,
    setGetTurkeyParams,
    selector,
    setSelector,
    setNumber,
    page,
    isMobile,
    isIOS,
}) => {
    const [getTurkeyParamsFilter, setGetTurkeyParamsFilter] = useState({
        cityId: '',
        filialId: '',
        forSearch: '',
        status: '',
        dateFrom: '',
        dateTo: '',
        selector: 10,
        plagination: page,
    });
    const [showAll, setShowAll] = useState(false);
    const [showAllCity, setShowAllCity] = useState(false);
    const [activeStatus, setActiveStatus] = useState(null);
    const [activeCity, setActiveCity] = useState(null);
    const [activeSelector, setActiveSelector] = useState(null);
    const [startDate, setStartDate] = useState('');
    const [endDate, setEndDate] = useState('');
    const [status, setStatus] = useState('');
    const [city, setCity] = useState(null);
    const [selectedStatus, setSelectedStatus] = useState(null);

    const { data: SecondData } = useQuery(['orders', getTurkeyParamsFilter], getOrders, {
        keepPreviousData: true,
    });
    const [marginBottom, setMarginBottom] = useState({ bottom: '100%', top: '58px' });
    const [bottom, setBottom] = useState('130px');
    useEffect(() => {
        const handleResize = () => {
            if (window.innerWidth < 768) {
                if (isMobile) {
                    if (isIOS) {
                        setMarginBottom({ bottom: '97%', top: '90px' });
                    } else {
                        setMarginBottom({ bottom: '100%', top: '50px' });
                    }
                    setBottom('130px');
                }
            } else {
                setMarginBottom({ bottom: '100%', top: '0px' });
                setBottom('-100px');
            }
        };
        handleResize();
        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, [isMobile, isIOS]);
    const contentStyles = {
        height: marginBottom.bottom,
        marginTop: marginBottom.top,
    };
    const contentStylesBottom = {
        bottom: bottom,
    };
    const totalElements = SecondData?.totalElements;
    const orderPriority = {
        NEW: 1,
        CHINA: 2,
        ON_ROAD_CHINA: 3,
        ON_BORDER: 4,
        ALMATY: 6,
        ON_ROAD: 7,
        SORTING: 5,
        CITY: 8,
        IN_STOCK: 9,
        SUB_FILIAL: 10,
        FOR_ISSUE: 11,
        ISSUE: 12,
        DONE: 13,
    };

    const sortedOrdersData = ordersData?.sort((a, b) => {
        return orderPriority[a.name] - orderPriority[b.name];
    });
    const displayedOrders = showAll ? sortedOrdersData : sortedOrdersData.slice(0, 4);
    const displayedCity = showAllCity ? citiesData : citiesData.slice(0, 5);
    const toggleShowAll = () => {
        setShowAll(!showAll);
    };
    const toggleShowAllCity = () => {
        setShowAllCity(!showAllCity);
    };
    const closeModal = () => {
        setShowModal(false);
        setActiveFilter(false);
    };
    const selectStatus = (value) => {
        setSelectedStatus(value);
        setActiveStatus(value);
        setStatus(value);
        setActiveSelector(value);
        setGetTurkeyParamsFilter({
            cityId: city,
            filialId: '',
            forSearch: '',
            status: value,
            dateFrom: startDate,
            dateTo: endDate,
            selector: selector,
            plagination: page,
        });
    };
    const handleChange = (event) => {
        setActiveSelector(event);
        const selectedNumber = event;
        setGetTurkeyParamsFilter({
            cityId: city,
            filialId: '',
            forSearch: '',
            status: status,
            dateFrom: startDate,
            dateTo: endDate,
            selector: selector,
            plagination: page,
        });
        setNumber(selectedNumber);
    };
    const handleChangeCity = (value) => {
        setCity(value);
        setActiveCity(value);
        setGetTurkeyParamsFilter({
            cityId: value,
            filialId: '',
            forSearch: '',
            status: status,
            dateFrom: startDate,
            dateTo: endDate,
            selector: selector,
            plagination: page,
        });
    };
    const handleDateChange = (dates) => {
        const [start, end] = dates;
        setStartDate(start ? format(start, 'yyyy-MM-dd') : null);
        setEndDate(end ? format(end, 'yyyy-MM-dd') : null);
        setGetTurkeyParamsFilter({
            cityId: city,
            filialId: '',
            forSearch: '',
            status: status,
            dateFrom: start ? format(start, 'yyyy-MM-dd') : null,
            dateTo: end ? format(end, 'yyyy-MM-dd') : null,
            selector: selector,
            plagination: page,
        });
    };
    const changeTable = () => {
        setShowModal(false);
        setActiveFilter(false);
        setGetTurkeyParams({
            cityId: city,
            filialId: '',
            forSearch: '',
            status: status,
            dateFrom: startDate,
            dateTo: endDate,
            selector: selector,
            plagination: page,
        });
    };
    if (!visible) return null;
    return (
        <div style={contentStyles} className={s['container']}>
            <div className={s['content']}>
                <div className={s['header']}>
                    <p className={s['header-text']}>{translation('filtersTitle')}</p>
                    <button onClick={closeModal} className={s['header-button']}>
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="23"
                            height="23"
                            viewBox="0 0 23 23"
                            fill="none"
                        >
                            <path
                                d="M2.37109 2.5L11.3711 11.5M11.3711 11.5L20.3711 2.5M11.3711 11.5L2.37109 20.5M11.3711 11.5L20.3711 20.5"
                                stroke="#D3D4DD"
                                stroke-width="3.21429"
                                stroke-linecap="square"
                            />
                        </svg>
                    </button>
                </div>
                <div className={s['status']}>
                    <div className={s['status-header']}>
                        <p className={s['status-header-text']}>
                            {translation('filterStatusOrder')}
                        </p>
                        <button className={s['status-header-button']} onClick={toggleShowAll}>
                            {showAll
                                ? `${translation('filterClose')}`
                                : `${translation('filterAllShow')}`}
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="27"
                                height="26"
                                viewBox="0 0 27 26"
                                fill="none"
                            >
                                <path
                                    fill-rule="evenodd"
                                    clip-rule="evenodd"
                                    d="M14.118 12.9999L10.5024 9.38443L11.9167 7.97021L16.9464 12.9999L11.9167 18.0297L10.5024 16.6155L14.118 12.9999Z"
                                    fill="#100E71"
                                />
                            </svg>
                        </button>
                    </div>
                    <div className={s['order-create']}>
                        {displayedOrders?.map((order) => (
                            <button
                                onClick={() => selectStatus(order.name)}
                                style={StatusColor(order.name_ru, selectedStatus === order.name)}
                                key={order.color_id}
                                className={`${s['button-filter']} ${activeStatus === order.name ? s['activeStatus'] : ''}`}
                            >
                                {language === 'ru' ? order.name_ru : order.name_kz}
                            </button>
                        ))}
                    </div>
                </div>
                <div className={s['selector']}>
                    <p className={s['selector-text']}>{translation('filterSunOrder')}</p>
                    <div className={s['selector-number']}>
                        {[10, 50, 100].map((num) => (
                            <button
                                key={num}
                                onClick={() => handleChange(num)}
                                className={`${s['selector-number-10']} ${activeSelector === num ? s['activeSelector'] : ''}`}
                            >
                                {num}
                            </button>
                        ))}
                    </div>
                </div>
                {role === 'RoleLeadAdmin' ? (
                    <div className={s['status']}>
                        <div className={s['status-header']}>
                            <p className={s['status-header-text']}>Город</p>
                            <button
                                className={s['status-header-button']}
                                onClick={toggleShowAllCity}
                            >
                                {showAllCity
                                    ? `${translation('filterClose')}`
                                    : `${translation('filterAllShow')}`}
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="27"
                                    height="26"
                                    viewBox="0 0 27 26"
                                    fill="none"
                                >
                                    <path
                                        fill-rule="evenodd"
                                        clip-rule="evenodd"
                                        d="M14.118 12.9999L10.5024 9.38443L11.9167 7.97021L16.9464 12.9999L11.9167 18.0297L10.5024 16.6155L14.118 12.9999Z"
                                        fill="#100E71"
                                    />
                                </svg>
                            </button>
                        </div>
                        <div className={s['order-create']}>
                            {displayedCity?.map((city) => (
                                <button
                                    onClick={() => handleChangeCity(city.id)}
                                    key={city.id}
                                    className={`${s['button-filter-city']} ${activeCity === city.id ? s['activeCity'] : ''}`}
                                >
                                    {city.name}
                                </button>
                            ))}
                        </div>
                    </div>
                ) : (
                    ''
                )}

                <div className={s['date']}>
                    <p className={s['status-header-text']}>{translation('filterDateOrder')}</p>
                    <DatePicker
                        className={s['react-datepicker']}
                        selected={startDate ? parseISO(startDate) : null}
                        onChange={handleDateChange}
                        startDate={startDate ? parseISO(startDate) : null}
                        endDate={endDate ? parseISO(endDate) : null}
                        selectsRange
                        placeholderText={translation('filterDateBetween')}
                        locale="ru"
                        dateFormat="dd.MM.yyyy"
                    />
                    <div className={s['line']}>
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="244"
                            height="2"
                            viewBox="0 0 244 2"
                            fill="none"
                        >
                            <path d="M0.371094 1H243.371" stroke="#100E71" stroke-width="2" />
                        </svg>
                    </div>
                </div>
            </div>
            <div style={contentStylesBottom} className={s['footer']}>
                <p className={s['footer-text']}>
                    {translation('filterFind')}
                    {totalElements}
                </p>
                <button onClick={changeTable} className={s['footer-button']}>
                    {translation('filterButton')}
                </button>
            </div>
        </div>
    );
};
export default FilterChina;
